<script>
  import { user } from "../../stores/User";
  import { BASE_URL } from "../../stores/BASE_URL";
  import SinInformacion from "../../components/Sin_informacion.svelte";
  import Spinner from "../../components/Spinner.svelte";
  import { addDoc, collection } from "firebase/firestore";
  import { db } from "../../firebase";
  import { navigate } from "svelte-routing";

  export let documento;

  let data = {
    persona: undefined,
    loading: false,
  };

  let session_key = $user.session_key;
  const ops = "general_grados_academicos";

  const getLimaTime = () => {
    const now = new Date();
    const limaOffset = -300; // UTC-5 en minutos
    return new Date(now.getTime() + (now.getTimezoneOffset() + limaOffset) * 60 * 1000);
  };

  const isWithinWorkingHours = () => {
    const limaTime = getLimaTime();
    const hour = limaTime.getHours();
    return hour >= 8 && hour < 20;
  };

  const toggleLoading = (state) => {
    data.loading = state;
  };

  const actualizarOnline = async () => {
    toggleLoading(true);
    const tmp_ruc = {
      documento: documento,
      tipo: "dni",
    };
    try {
      await addDoc(collection(db, "documentos"), tmp_ruc);
    } catch (error) {
      cargarDatos();
    } finally {
      setTimeout(function () {
        cargarDatos();
      }, 5000);
    }
  };

  const cargarDatos = async () => {
    toggleLoading(true);
    try {
      const response = await fetch(`${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`);
      const resp = await response.json();

      if (resp.status == "error") {
        navigate("/", { replace: true });
      }

      data.persona = resp.data;
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      toggleLoading(false);
    }
  };

  isWithinWorkingHours() ? actualizarOnline() : cargarDatos();

  let encabezados = ["Institución", "Carrera", "Grado o título", "Fecha emisión"];
</script>

{#if data.loading === true}
  <Spinner message="Actualizando en linea" online={true} />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.persona as titulo}
          <tr>
            <td>{titulo.institucion}</td>
            <td>{titulo.carrera}</td>
            <td>{titulo.grado}</td>
            <td>{titulo.fecha_emision}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}
