<script>
  import { user } from "../../stores/User";
  import { BASE_URL } from "../../stores/BASE_URL";
  import SinInformacion from "../../components/Sin_informacion.svelte";
  import getMoneda, { redondear, dateFormat } from "../../services/moneda.js";
  import Modal from "sv-bootstrap-modal";
  import Spinner from "../../components/Spinner.svelte";
  import { navigate } from "svelte-routing";

  export let ruc;

  let data = {
    empresa: undefined,
    loading: false,
  };

  let session_key = $user.session_key;

  const cargarDatos = async () => {
    const ops = "deudas_detalle";
    data.loading = true;
    const response = await fetch(`${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}`);
    const resp = await response.json();

    if (resp.status == "error") {
      navigate("/", { replace: true });
    }

    data = {
      empresa: resp.data,
      loading: false,
    };

    if (data.empresa.length > 0) {
      data.empresa.total_mes1 = redondear(data.empresa.map((item) => parseFloat(item.mes1)).reduce((prev, curr) => prev + curr, 0));
      data.empresa.total_mes2 = redondear(data.empresa.map((item) => parseFloat(item.mes2)).reduce((prev, curr) => prev + curr, 0));
      data.empresa.total_mes3 = redondear(data.empresa.map((item) => parseFloat(item.mes3)).reduce((prev, curr) => prev + curr, 0));
      data.empresa.total_mes4 = redondear(data.empresa.map((item) => parseFloat(item.mes4)).reduce((prev, curr) => prev + curr, 0));
    }
  };

  let isOpen = false;
  let empresa = "";

  let modal = {
    empresa: undefined,
    loading: false,
  };

  const getDetalleEmpresa = async (emp) => {
    empresa = emp;
    isOpen = true;
    modal.loading = true;

    const ops = "deudas_por_empresa";
    const response = await fetch(`${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}&empresa=${emp}`);
    const resp = await response.json();

    modal = {
      empresa: resp.data,
      loading: false,
    };

    //modal.empresa.meses =  modal.empresa.meses.reverse()
  };

  cargarDatos();

  const getColor = (estado) => {
    let color = "--gris";
    switch (estado) {
      case "NOR":
        color = "--verde";
        break;
      case "CPP":
        color = "--amarillo";
        break;
      case "DEF":
        color = "--naranja";
        break;
      case "DUD":
        color = "--rojo";
        break;
      case "PER":
        color = "--negro";
        break;
      default:
        color = "--gris";
        break;
    }
    return color;
  };
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <div class="table-responsive">
    {#if data.empresa.length > 0}
      <table class="table table-striped table-sm">
        <thead class="text-center">
          <tr>
            <th class="text-secondary fw-normal" rowspan="2">Entidad</th>
            <th class="text-secondary fw-normal" colspan="3">Deuda Anterior</th>
            <th class="text-secondary fw-normal" colspan="3">{data.empresa[0].des_mes1}</th>
          </tr>
          <tr>
            <th class="text-secondary fw-normal">{data.empresa[0].des_mes4}</th>
            <th class="text-secondary fw-normal">{data.empresa[0].des_mes3}</th>
            <th class="text-secondary fw-normal">{data.empresa[0].des_mes2}</th>

            <th class="text-secondary fw-normal">Fecha</th>
            <th class="text-secondary fw-normal">Calif.</th>
            <th class="text-secondary fw-normal">Monto</th>
          </tr>
        </thead>
        <tbody class="text-end">
          {#each data.empresa as detalle}
            <tr>
              <td class="text-start">
                <span class="text-primary text-action" on:click={getDetalleEmpresa(detalle.empresa)}>
                  {detalle.empresa}
                </span>
              </td>
              <td>
                {getMoneda(detalle.mes4)}
              </td>
              <td>{getMoneda(detalle.mes3)}</td>
              <td>{getMoneda(detalle.mes2)}</td>
              <td class="text-center">{dateFormat(detalle.fechareporte, "####-##-##")}</td>
              <td class="text-center text-white" style="background-color: var({getColor(detalle.calificacion)})">{detalle.calificacion}</td>
              <td>{getMoneda(detalle.mes1)}</td>
            </tr>
          {/each}
        </tbody>
        <tfoot class="text-end">
          <tr>
            <td class="text-secondary fw-normal text-start">Total</td>
            <td>{getMoneda(data.empresa.total_mes4)}</td>
            <td>{getMoneda(data.empresa.total_mes3)}</td>
            <td>{getMoneda(data.empresa.total_mes2)}</td>
            <td />
            <td />
            <td>{getMoneda(data.empresa.total_mes1)}</td>
          </tr>
        </tfoot>
      </table>
    {:else}
      <SinInformacion />
    {/if}
  </div>

  <Modal bind:open={isOpen} dialogClasses="modal-fullscreen modal-dialog-scrollable">
    <div class="modal-header">
      <h5 class="modal-title text-secondary">{empresa}</h5>
      <button type="button" class="btn-close" on:click={() => (isOpen = false)} />
    </div>
    <div class="modal-body">
      {#if modal.loading === true}
        <Spinner />
      {:else if modal.empresa !== undefined}
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-sm table-empresa">
            <thead class="text-center">
              <tr>
                <th class="text-secondary fw-normal">OPERACIÓN</th>
                {#each modal.empresa.meses as mes}
                  <th class="text-secondary fw-normal" colspan="2">{mes.tabla}</th>
                {/each}
              </tr>
              <tr>
                <th class="text-secondary fw-normal" />
                {#each modal.empresa.meses as mes}
                  <th class="text-secondary fw-normal">Monto</th>
                  <th class="text-secondary fw-normal">Días V.</th>
                {/each}
              </tr>
              <tr>
                <th class="text-secondary fw-normal">CALIFICACIÓN</th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes1}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes1}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes2}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes2}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes3}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes3}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes4}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes4}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes5}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes5}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes6}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes6}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes7}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes7}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes8}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes8}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes9}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes9}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes10}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes10}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes11}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes11}
                </th>
                <th class="fw-normal {modal.empresa.encabezado[0].cali_mes12}" colspan="2">
                  {modal.empresa.encabezado[0].cali_mes12}
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <td class="text-secondary fw-normal">RESUMEN</td>
                <td colspan="24" />
              </tr>
              {#each modal.empresa.encabezado as e}
                <tr class="text-end">
                  <td class="text-start">{e.resumen}</td>
                  <td>{getMoneda(e.mes1)}</td>
                  <td class="text-secondary">{e.condicion1}</td>
                  <td>{getMoneda(e.mes2)}</td>
                  <td class="text-secondary">{e.condicion2}</td>
                  <td>{getMoneda(e.mes3)}</td>
                  <td class="text-secondary">{e.condicion3}</td>
                  <td>{getMoneda(e.mes4)}</td>
                  <td class="text-secondary">{e.condicion4}</td>
                  <td>{getMoneda(e.mes5)}</td>
                  <td class="text-secondary">{e.condicion5}</td>
                  <td>{getMoneda(e.mes6)}</td>
                  <td class="text-secondary">{e.condicion6}</td>
                  <td>{getMoneda(e.mes7)}</td>
                  <td class="text-secondary">{e.condicion7}</td>
                  <td>{getMoneda(e.mes8)}</td>
                  <td class="text-secondary">{e.condicion8}</td>
                  <td>{getMoneda(e.mes9)}</td>
                  <td class="text-secondary">{e.condicion9}</td>
                  <td>{getMoneda(e.mes10)}</td>
                  <td class="text-secondary">{e.condicion10}</td>
                  <td>{getMoneda(e.mes11)}</td>
                  <td class="text-secondary">{e.condicion11}</td>
                  <td>{getMoneda(e.mes12)}</td>
                  <td class="text-secondary">{e.condicion12}</td>
                </tr>
              {/each}
            </thead>
            <tbody>
              {#each modal.empresa.cuerpo as c}
                <tr class="text-end">
                  <td class="text-start">{c.descripcion}</td>
                  <td>{getMoneda(c.mes1)}</td>
                  <td class="text-secondary">{c.condicion1}</td>
                  <td>{getMoneda(c.mes2)}</td>
                  <td class="text-secondary">{c.condicion2}</td>
                  <td>{getMoneda(c.mes3)}</td>
                  <td class="text-secondary">{c.condicion3}</td>
                  <td>{getMoneda(c.mes4)}</td>
                  <td class="text-secondary">{c.condicion4}</td>
                  <td>{getMoneda(c.mes5)}</td>
                  <td class="text-secondary">{c.condicion5}</td>
                  <td>{getMoneda(c.mes6)}</td>
                  <td class="text-secondary">{c.condicion6}</td>
                  <td>{getMoneda(c.mes7)}</td>
                  <td class="text-secondary">{c.condicion7}</td>
                  <td>{getMoneda(c.mes8)}</td>
                  <td class="text-secondary">{c.condicion8}</td>
                  <td>{getMoneda(c.mes9)}</td>
                  <td class="text-secondary">{c.condicion9}</td>
                  <td>{getMoneda(c.mes10)}</td>
                  <td class="text-secondary">{c.condicion10}</td>
                  <td>{getMoneda(c.mes11)}</td>
                  <td class="text-secondary">{c.condicion11}</td>
                  <td>{getMoneda(c.mes12)}</td>
                  <td class="text-secondary">{c.condicion12}</td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {/if}
    </div>
  </Modal>
{/if}

<style>
  .table-empresa {
    font-size: 0.7rem;
  }

  .table-empresa td {
    white-space: nowrap;
  }

  .modal-xl {
    width: 100%;
    max-width: 100%;
  }

  .SCAL {
    background-color: var(--gris);
    color: var(--blanco);
  }
  .NOR {
    background-color: var(--verde);
    color: var(--blanco);
  }
  .CPP {
    background-color: var(--amarillo);
    color: var(--negro);
  }
  .DEF {
    background-color: var(--naranja);
    color: var(--blanco);
  }
  .DUD {
    background-color: var(--rojo);
    color: var(--blanco);
  }
  .PER {
    background-color: var(--negro);
    color: var(--blanco);
  }

  .text-action {
    cursor: pointer;
  }
  .text-action:hover {
    --bs-text-opacity: 0.7;
  }
</style>
