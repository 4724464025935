<script>
  import { user } from "../../stores/User";
  import { BASE_URL } from "../../stores/BASE_URL";
  import SinInformacion from "../../components/Sin_informacion.svelte";
  import Spinner from "../../components/Spinner.svelte";
  import { navigate } from "svelte-routing";

  export let ruc;

  let data = {
    empresa: undefined,
    loading: false,
  };

  let session_key = $user.session_key;

  const cargarDatos = async () => {
    const ops = "general_empleados";
    data.loading = true;
    const response = await fetch(`${BASE_URL}empresa.php?ruc=${ruc}&session_key=${session_key}&ops=${ops}`);
    const resp = await response.json();

    if (resp.status == "error") {
      navigate("/", { replace: true });
    }

    data = {
      empresa: resp.data,
      loading: false,
    };
  };

  let encabezados = ["Periodo", "Trabajadores", "Pensionistas", "Prestadores de servicios"];

  cargarDatos();
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.empresa.length === 0}
  <SinInformacion />
{:else if data.empresa !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.empresa as emp}
          <tr>
            <td>{emp.periodo}</td>
            <td>{emp.trabajadores}</td>
            <td>{emp.pensionistas}</td>
            <td>{emp.prestadores_servicio}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}
