<script>
  import { user } from "../../stores/User";
  import { BASE_URL } from "../../stores/BASE_URL";
  import SinInformacion from "../../components/Sin_informacion.svelte";
  import { Link } from "svelte-routing";
  import Spinner from "../../components/Spinner.svelte";
  import { navigate } from "svelte-routing";

  export let documento;

  let btn_status = true;

  let data = {
    persona: undefined,
    loading: false,
  };

  let session_key = $user.session_key;

  const cargarDatos = async () => {
    const ops = "general_familiares";
    data.loading = true;
    const response = await fetch(`${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`);
    const resp = await response.json();

    if (resp.status == "error") {
      navigate("/", { replace: true });
    }

    data = {
      persona: resp.data,
      loading: false,
    };
  };

  cargarDatos();

  const encabezados = ["Filiación", "Documento", "Nombres", "Sexo", "Nacimiento"];

  let data_hermanos = {
    persona: undefined,
    loading: false,
  };

  const getHermanos = async () => {
    const ops = "general_hermanos";
    btn_status = false;
    data_hermanos.loading = true;
    const response = await fetch(`${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`);
    const resp = await response.json();
    data_hermanos = {
      persona: resp.data,
      loading: false,
    };
  };

  const encabezadoHermanos = ["Filiación", "Documento", "Nombres", "Padre", "Madre", "Sexo", "Nacimiento", "Departamento"];
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  {#if btn_status}
    <button class="btn btn-primary btn-sm" on:click={getHermanos}> Ver hermanos </button>
  {/if}
{:else if data.persona !== undefined}
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          {#each encabezados as encabezado}
            <th class="text-secondary fw-normal">{encabezado}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each data.persona as familiar}
          <tr>
            <td>{familiar.filiacion}</td>
            <td>
              {#if familiar.documento.length === 8}
                <Link class="no_sub" to="/persona/{familiar.documento}">{familiar.documento}</Link>
              {:else}
                {familiar.documento}
              {/if}
            </td>
            <td>{familiar.nombres}</td>
            <td>{familiar.sexo}</td>
            <td>{familiar.nacimiento}</td>
          </tr>
        {/each}
      </tbody>
    </table>

    {#if btn_status}
      <button class="btn btn-primary btn-sm" on:click={getHermanos}> Ver hermanos </button>
    {/if}
  </div>
{/if}

{#if data_hermanos.loading === true}
  <Spinner />
{:else if data_hermanos.persona !== undefined}
  {#if data_hermanos.persona.length === 0}
    <SinInformacion />
  {:else}
    <div class="table-responsive">
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            {#each encabezadoHermanos as encabezado}
              <th class="text-secondary fw-normal">{encabezado}</th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#each data_hermanos.persona as hermano}
            <tr>
              <td>{hermano.filiacion}</td>
              <td>
                <Link class="no_sub" to="/persona/{hermano.documento}">{hermano.documento}</Link>
              </td>
              <td>{hermano.nombres}</td>
              <td>{hermano.padre}</td>
              <td>{hermano.madre}</td>
              <td>{hermano.sexo}</td>
              <td>{hermano.nacimiento}</td>
              <td>{hermano.departamento}</td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  {/if}
{/if}
