<script>
    import Logo from "../images/Logo.svelte";
    let tipo_documento = "DNI";
    let documento = "";

    let data_user = {
        user: undefined,
        message: undefined,
        loading: false,
    };

    const login = () => {};
</script>

<div class="Login vh-100 d-flex justify-content-center align-items-center">
    <form on:submit|preventDefault={login} class="p-5 text-secondary bg-white shadow-sm rounded form-login">
        <div class="mb-1 text-center">
            <Logo />
        </div>
        <p class="mb-3 text-center">Sistema de Búsquedas Integradas</p>
        <h3 class="mb-3 text-dark text-center">Verificar</h3>
        <label class="form-label w-100">
            Tipo de documento
            <select class="form-select" bind:value={tipo_documento}>
                <option value="DNI">DNI</option>
                <option value="CARNET EXT.">CARNET EXT.</option>
                <option value=""></option>
            </select>
        </label>
        <label class="form-label w-100">
            Número de documento
            <input class="form-control mb-2" type="text" placeholder="" bind:value={documento} />
        </label>
        <button class="form-control btn btn-primary {data_user.loading ? 'disabled' : ''}" type="submit">{data_user.loading ? "Cargando" : "Verificar"}</button>

        {#if data_user.message !== undefined}
            <div class="alert {data_user.message !== 'Bienvenido' ? 'alert-danger' : 'alert-success'} mt-2 small" role="alert">
                {data_user.message}
            </div>
        {/if}
    </form>
</div>

<style>
    .form-login {
        max-width: 400px;
    }
</style>
