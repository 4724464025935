<script>
  import { user } from "../../stores/User";
  import { BASE_URL } from "../../stores/BASE_URL";
  import SinInformacion from "../../components/Sin_informacion.svelte";
  import Spinner from "../../components/Spinner.svelte";
  import { navigate } from "svelte-routing";

  export let documento;

  let data = {
    persona: undefined,
    loading: false,
  };

  let session_key = $user.session_key;

  const cargarDatos = async () => {
    const ops = "laboral_seguro";
    data.loading = true;
    const response = await fetch(`${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`);
    const resp = await response.json();

    if (resp.status == "error") {
      navigate("/", { replace: true });
    }

    data = {
      persona: resp.data,
      loading: false,
    };
  };

  cargarDatos();
</script>

{#if data.loading === true}
  <Spinner message="Actualizando en linea" online={true} />
{:else if data.persona !== undefined && data.persona.codError !== undefined}
  {#if data.persona.codError == "0"}
    <div class="table-responsive">
      <table class="table table-striped table-sm">
        <tbody>
          <tr>
            <th class="text-secondary fw-normal">Autogenerado</th>
            <td>{data.persona.autogenerado}</td>
          </tr>
          <tr>
            <th class="text-secondary fw-normal">Desde</th>
            <td>{data.persona.fecIns}</td>
          </tr>
          <tr>
            <th class="text-secondary fw-normal">Vigente hasta</th>
            <td>{data.persona.fecVigHasta}</td>
          </tr>
          <tr>
            <th class="text-secondary fw-normal">Centro de asistencia</th>
            <td>{data.persona.desCentro}</td>
          </tr>
          <tr>
            <th class="text-secondary fw-normal">Ubicación Domicilio</th>
            <td>{data.persona.desUbiDom}</td>
          </tr>
          <tr>
            <th class="text-secondary fw-normal">Dirección Domicilio</th>
            <td
              >{data.persona.nomCalle}
              {data.persona.numManzanaKm}
              {data.persona.nombUrbanizacion}</td
            >
          </tr>
          <tr>
            <th class="text-secondary fw-normal">Nacimiento</th>
            <td>{data.persona.fecNac}</td>
          </tr>
          <tr>
            <th class="text-secondary fw-normal">Primer empleo</th>
            <td>{data.persona.rucEmpl}</td>
          </tr>
        </tbody>
      </table>
    </div>
  {:else if data.persona.codError == "51"}
    <p class="text-center text-secondary m-0">Asegurado afiliado a una EPS</p>
  {:else}
    <SinInformacion />
  {/if}
{:else}
  <SinInformacion />
{/if}
